<template>
  <div class="bt-transaksi pa-5">
    <h6 class="text-h5 font-weight-bold indigo--text text--lighten-1 d-flex align-center">
      <div class="rounded-pill indigo lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3">
        <v-icon small color="white">mdi-book-multiple</v-icon>
      </div>
      Laporan Saldo Anggota - Simpanan Sukarela
    </h6>
    <div>
      <v-select solo label="Majelis" class="mb-3 mt-3" hide-details :items="opt.rembug"
        v-model="form.data.kode_rembug" />

      <v-btn color="blue-grey mt-0" class="mx-auto white--text" block @click="getMemberInformationHistoryFinancing()">
        Search
        <v-icon right>mdi-magnify</v-icon>
      </v-btn>
    </div>
    <v-container class="pa-0 mt-5">
      <p class="text-h6 text-center" v-if="form.data.lists.length == 0" style="color: white;">
        Data not found!
      </p>
      <v-card class="white elevation-3 rounded-lg pa-3 mb-3" v-for="(item, index) in form.data.lists" :key="index">
        <v-card-text>
          <div>{{ item.no_anggota }}</div>
          <div class="text-h4 text--primary">
            {{ item.nama_anggota }}
          </div>
          <div class="text-h6 indigo--text lighten-1 font-weight-black text-right">
            {{ thousandMask(item.simsuk)[0] }}
          </div>
        </v-card-text>
      </v-card>
    </v-container>

    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>

    <v-overlay v-model="overlay" class="align-center justify-center">
      <v-progress-circular color="primary" size="64" indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import helper from "@/utils/helper";
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
export default {
  name: "KeanggotaanSaldoSukarela",
  components: {},
  data() {
    return {
      form: {
        data: {
          kode_rembug: null,
          tanggal: null,
          lists: [],
        }
      },
      opt: {
        rembug: [],
        anggota: [],
        rekening: [],
      },
      dateShow: false,
      alert: {
        show: false,
        msg: ''
      },
      overlay: false,
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...helper,
    async getRembug() {
      try {
        this.overlay = true;
        this.opt.rembug = [];

        const {
          kode_cabang = null
        } = this.user;

        let payload = new FormData();
        payload.append("kode_cabang", kode_cabang);

        const response = await services.rembugRead(payload, this.user.token);
        if (response.status === 200) {
          response.data.data.map((item) => {
            this.opt.rembug.push({
              text: item.nama_rembug,
              value: item.kode_rembug,
            });
          });
        } else {
          this.alert = {
            show: true,
            msg: response.data.msg || null,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || '',
        };
      } finally {
        this.overlay = false;
      }
    },
    async getMemberInformationHistoryFinancing() {
      try {
        this.overlay = true;
        this.form.data.lists = [];

        let payload = new FormData()
        payload.append('kode_rembug', this.form.data.kode_rembug);
        payload.append('today', this.form.data.tanggal);
        const response = await services.infoMember(payload, this.user.token)
        if (response.status === 200 && response.data.data && response.data.data !== null) {
          this.form.data.lists = response.data.data;
        } else {
          this.alert = {
            show: true,
            msg: response.data.msg || null,
          }
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || ''
        }
      } finally {
        this.overlay = false;
      }
    },
  },
  mounted() {
    this.getRembug();
  },
};
</script>
